<template>
  <div :key="componentKey">
    <div v-if="!isMobile()">
      <div class="page page-entry-harmony">
        <full-page ref="fullpage" :options="options" id="fullpage">
          <div id="first-Page" class="section">
            <!--Section one-->
            <div class="page-section section-first">
              <VideoEntrerHarmony />
              <div class="page-section-col block-text">
                <div>
                  <h1
                    class="page-title"
                    :class="{ 'fade-in-right': AnimtxtBlur }"
                  >
                    {{ $t("titleEntry")["0"] }} <br />
                    {{ $t("titleEntry")["1"] }}
                  </h1>
                </div>
              </div>

              <div class="scroll-bottom">
                <a
                  href="#"
                  aria-label="scroll to next"
                  class="btn btn-white btn-scroll"
                  v-on:click="scrollToNext"
                >
                  <svg
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.799 1L10.8995 10.8995L1 1"
                      stroke="#F3C678"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.799 1L10.8995 10.8995L1 1"
                      stroke="#F3C678"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <!--Section One-->
          </div>

          <div class="section">
            <!--Section Image-->
            <div class="page-section section-image">
              <div class="page-section-col">
                <picture class="loadTemp">
            
                  <img
                    src=""
                    :dta-src="
                      this.$pathprod +
                      'images/entrer_harmony/entry-harmony-bg-2.jpg'
                    "
                    srcset=""
                    :dta-srcset="
                      this.$pathprod +
                      'images/entrer_harmony/entry-harmony-bg-2-retina.jpg 2x'
                    "
                    alt="entry-harmony-bg-2"
                    width="1920"
                    height="937"
                  />
                </picture>
              </div>
            </div>
            <!--Section Image-->
          </div>

          <div class="section">
            <!--Section Geste précis-->
            <div id="geste-precis" class="page-section section-geste-precis" @wheel="wheelIntersectionGestePrecis">
              <div class="page-section-col">
                <div class="geste-precis-content">
                  <div class="geste-precis-top">
                    <h2 class="title-h2 anim-txt-focus">
                      {{ $t("dataGestePrecis")["0"] }}
                    </h2>
                    <picture>
                    
                      <img
                        :src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-becardmap.svg'
                        "
                        alt="entry-harmony-becardmap"
                        width="85"
                        height="63"
                      />
                    </picture>
                  </div>
                  <div class="geste-precis-bottom">
                    <div class="geste-bottom-top">
                      <p class="text-gold">{{ $t("dataGestePrecis")["1"] }}</p>
                    </div>
                    <div class="geste-bottom-content">
                      <div class="geste-bottom-content-col">
                        <img
                          class="quote-before"
                          :src="
                            this.$pathprod +
                            'images/entrer_harmony/quote-before.svg'
                          "
                          alt="quote-before"
                          width="39"
                          height="25"
                        />
                      </div>
                      <div class="geste-bottom-content-col">
                        <p class="text-geste">
                          <strong>{{ $t("dataGestePrecis")["2"] }}</strong>
                          {{ $t("dataGestePrecis")["3"] }}
                        </p>
                        <p class="text-geste">
                          <strong>{{ $t("dataGestePrecis")["4"] }}</strong>
                          {{ $t("dataGestePrecis")["5"] }}
                        </p>
                        <p class="text-geste">
                          <strong>{{ $t("dataGestePrecis")["6"] }}</strong>
                          {{ $t("dataGestePrecis")["7"] }}
                        </p>
                        <p class="text-geste">
                          {{ $t("dataGestePrecis")["8"] }}
                          <strong>{{ $t("dataGestePrecis")["9"] }}</strong>
                          {{ $t("dataGestePrecis")["10"] }}
                        </p>
                        <p class="text-geste">
                          <strong>{{ $t("dataGestePrecis")["11"] }}</strong>
                          {{ $t("dataGestePrecis")["12"] }}
                          <strong>{{ $t("dataGestePrecis")["13"] }}</strong>
                          {{ $t("dataGestePrecis")["14"] }}
                        </p>
                        <p class="text-geste">
                          {{ $t("dataGestePrecis")["15"] }}
                          <img
                            class="quote-after"
                            :src="
                              this.$pathprod +
                              'images/entrer_harmony/quote-after.svg'
                            "
                            alt="quote-after"
                          />
                        </p>

                        <p class="geste-signature">
                          <strong>Fabienne Bétoulaud</strong>
                          {{ $t("dataGestePrecis")["16"] }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-section-col">
                <figure>
                  <picture class="loadTemp">
                
                    <img
                      src=""
                      :dta-src="
                        this.$pathprod +
                        'images/entrer_harmony/entry-harmony-fabienne.jpg'
                      "
                      srcset=""
                      :dta-srcset="
                        this.$pathprod +
                        'images/entrer_harmony/entry-harmony-fabienne-retina.jpg 2x'
                      "
                      alt="entry-harmony-fabienne"
                      width="520"
                      height="900"
                    />
                  </picture>
                </figure>
              </div>
            </div>
            <!--Section Geste précis-->
          </div>

          <div class="section">
            <!--Section habillage-->
            <section class="section-habillage">
              <div class="page-section-item">
                <div class="habillage-abs">
                  <div
                    class="section-item-col sct-left"
                    v-for="habillage in habillages_Img"
                    :key="habillage.id"
                  >
                    <figure class="img-block" :data-img="habillage.data_img">
                      <picture>
                        
                        <img
                          :src="habillage.img"
                          :alt="habillage.alt"
                          class="section-item-img"
                        />
                      </picture>
                    </figure>
                  </div>
                </div>

                <div
                  class="section-item-col sct-right"
                  @wheel="wheelIntersectionHabillage"
                >
                  <hooper
                    class="slider-block-habillage"
                    :settings="hooperSettingsMainHabillage"
                  >
                    <slide
                      class="slider-item"
                      v-for="info_habil in info_habillages"
                      :key="info_habil.id"
                      data-index=""
                    >
                      <div class="cnt-bloc-grid">
                        <div class="cnt-bloc-left"></div>
                        <div class="ctn-img-bloc">
                          <div
                            class="section-item-content"
                            :data-sect="info_habil.data_sct"
                          >
                            <div class="section-item-child">
                              <div class="number">
                                {{ info_habil.id
                                }}<em class="number-point">.</em>
                              </div>
                              <div>
                                <strong class="item-desc">{{
                                  $t("dataHabillage")[info_habil.id - 1]
                                }}</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </slide>
                  </hooper>
                </div>
              </div>
            </section>
            <!--Section habillage-->
          </div>

          <div class="section">
            <!--Section Facade-->
            <div class="page-section section-facade">
              <div class="page-section-col">
                <div class="block-facade">
                  <div class="facade-col">
                    <div class="facade-img">
                      <picture class="loadTemp">
                     
                        <img
                          src=""
                          :dta-src="
                            this.$pathprod +
                            'images/entrer_harmony/entry-harmony-facade.jpg'
                          "
                          srcset=""
                          :dta-srcset="
                            this.$pathprod +
                            'images/entrer_harmony/entry-harmony-facade-retina.jpg 2x'
                          "
                          alt="entry-harmony-facade"
                          width="472"
                          height="522"
                        />
                      </picture>
                    </div>
                    <div class="facade-block">
                      <p class="facade-text">
                        <strong>{{ $t("dataFacade")["0"] }}</strong>
                        {{ $t("dataFacade")["1"] }}
                      </p>
                    </div>
                  </div>
                  <div class="facade-col">
                    <div class="facade-img">
                      <picture class="loadTemp">
                     
                        <img
                          src=""
                          :dta-src="
                            this.$pathprod +
                            'images/entrer_harmony/entry-harmony-facade-transparent.jpg'
                          "
                          srcset=""
                          :dta-srcset="
                            this.$pathprod +
                            'images/entrer_harmony/entry-harmony-facade-transparent-retina.jpg 2x'
                          "
                          alt="entry-harmony-facade-transparent"
                          width="467"
                          height="544"
                        />
                      </picture>
                    </div>
                    <div class="facade-block">
                      <p class="facade-text">
                        <strong>{{ $t("dataFacade")["2"] }}</strong>
                        {{ $t("dataFacade")["3"] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Section Facade-->
          </div>

          <div class="section">
            <div id="services-dynamiques" class="page-section section-dynamique-images section-dynamique-img-full" @wheel="wheelIntersectionDynamiqueServices" >
              <div class="page-section-col">
                <div class="dynamique-images-block">
                  <div class="dynamique-images-flou dynamic-images drop-attachment-fixed">
                    <picture class="loadTemp">
                    
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-dynamique-contrast.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-dynamique-contrast-retina.jpg 2x'
                        "
                        alt="entry-harmony-dynamique-contrast"
                        width="1920"
                        height="937"
                      />
                    </picture>
                    <div class="block-gold-dynamique">
                      <p class="block-gold-text">{{ $t("titleDS") }}</p>
                    </div>
                  </div>
                  <div class="dynamique-images-full dynamic-images drop-attachment-fixed">
                    <picture class="loadTemp">
              
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-dynamique-normal.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-dynamique-normal-retina.jpg 2x'
                        "
                        alt="entry-harmony-dynamique-normal"
                        width="1920"
                        height="937"
                      />
                    </picture>
                  </div>
                  <div class="block-key-number" :class="{ increment: incrementE }">
                    <div class="block-key-top"></div>
                    <div class="block-key-bottom">
                      <div class="block-gold-number">
                        <div class="block-gold-number-col">
                          <picture class="loadTemp">
                       
                            <img
                              src=""
                              :dta-src="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-parcours-img1.jpg'
                              "
                              srcset=""
                              :dta-srcset="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-parcours-img1-retina.jpg 2x'
                              "
                              alt="entry-harmony-parcours-img1"
                              class="parcours-img-1-mobile"
                              width="190"
                              height="387"
                            />
                          </picture>
                          <div class="key-number-list">
                            <div class="key-number-item">
                              <p class="key-number white">
                                {{ $t("dataChiffreKeyDS")["0"] }} <br />
                                <!-- <span class="number-to-animate" data-number="1000">1 000</span>  -->
                                <number
                                  ref="number1"
                                  :from="0"
                                  :to="1000"
                                  :format="theFormat"
                                  animationPaused
                                  :duration="2"
                                  easing="Power1.easeOut"
                                />
                                m<span class="m2">2</span>
                              </p>
                              <p class="key-desc">
                                {{ $t("dataChiffreKeyDS")["1"] }}
                              </p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number white">
                                <!-- <span class="number-to-animate" data-number="265">265</span>  -->
                                <number
                                  ref="number2"
                                  :from="0"
                                  :to="265"
                                  :format="theFormat"
                                  animationPaused
                                  :duration="2"
                                  easing="Power1.easeOut"
                                />
                                m<span class="m2">2</span>
                              </p>
                              <p class="key-desc">
                                {{ $t("dataChiffreKeyDS")["2"] }}
                              </p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number white">
                                <!-- <span class="number-to-animate" data-number="1370">1 370</span>  -->
                                <number
                                  ref="number3"
                                  :from="0"
                                  :to="1370"
                                  :format="theFormat"
                                  animationPaused
                                  :duration="2"
                                  easing="Power1.easeOut"
                                />
                                m<span class="m2">2</span>
                              </p>
                              <p class="key-desc">
                                {{ $t("dataChiffreKeyDS")["3"] }}
                              </p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number white">
                                <!-- <span class="number-to-animate" data-number="250">250</span> -->
                                <number
                                  ref="number4"
                                  :from="0"
                                  :to="250"
                                  :format="theFormat"
                                  animationPaused
                                  :duration="2"
                                  easing="Power1.easeOut"
                                />
                                m<span class="m2">2</span>
                              </p>
                              <p class="key-desc">
                                {{ $t("dataChiffreKeyDS")["4"] }}
                              </p>
                            </div>
                          </div>
                          <picture class="loadTemp">
                           
                            <img
                              src=""
                              :dta-src="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-parcours-img2.jpg'
                              "
                              srcset=""
                              :dta-srcset="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-parcours-img2-retina.jpg 2x'
                              "
                              alt="entry-harmony-parcours-img2"
                              class="parcours-img-2"
                              width="400"
                              height="280"
                            />
                          </picture>
                        </div>
                        <div class="block-gold-number-col">
                          <picture class="loadTemp">
                       
                            <img
                              src=""
                              :dta-src="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-parcours-img1.jpg'
                              "
                              srcset=""
                              :dta-srcset="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-parcours-img1-retina.jpg 2x'
                              "
                              alt="entry-harmony-parcours-img1"
                              class="parcours-img-1"
                              width="190"
                              height="387"
                            />
                          </picture>
                          <div class="block-col-content">
                            <h4 class="title-h4-black">
                              {{ $t("dataChiffreContent")["0"] }}
                            </h4>
                            <div class="list-item">
                              <h4 class="title-h4-black">
                                {{ $t("dataChiffreContent")["1"] }}
                              </h4>
                              <ul class="list-arrow">
                                <li>{{ $t("dataChiffreContent")["2"] }}</li>
                                <li>{{ $t("dataChiffreContent")["3"] }}</li>
                                <li>{{ $t("dataChiffreContent")["4"] }}</li>
                                <li>{{ $t("dataChiffreContent")["5"] }}</li>
                                <li>{{ $t("dataChiffreContent")["6"] }}</li>
                              </ul>
                            </div>
                            <div class="list-item">
                              <h4 class="title-h4-black">
                                {{ $t("dataChiffreContent")["7"] }}
                              </h4>
                              <ul class="list-arrow">
                                <li>
                                  <strong>{{
                                    $t("dataChiffreContent")["8"]
                                  }}</strong>
                                  {{ $t("dataChiffreContent")["9"] }}
                                </li>
                                <li>
                                  <strong>{{
                                    $t("dataChiffreContent")["10"]
                                  }}</strong>
                                  {{ $t("dataChiffreContent")["11"] }}
                                </li>
                                <li>
                                  <strong>{{
                                    $t("dataChiffreContent")["12"]
                                  }}</strong>
                                  {{ $t("dataChiffreContent")["13"] }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="section"
            @wheel.prevent="
              wheelIntersectionCarousel(
                $event,
                slider_block_carousel1,
                dynamique_services,
                section_harmony
              )
            "
          >
            <!--Section carousel 1-->
            <div class="page-section section-carousel">
              <div class="page-section-col slider-block-carousel1">
                <div
                  class="slide slider-item"
                  v-for="carousel in carousel1"
                  :key="carousel.id"
                  :data-anchor="'slideC' + carousel.id"
                >
                  <picture>
                    <img
                      :src="carousel.src"
                      :srcset="carousel.srcset"
                      :alt="carousel.alt"
                      @click="openLightbox(carousel.id, lightbox1)"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <!--Section carousel 1-->
          </div>

          <div class="section">
            <!--Section Harmony services-->
            <div class="page-section section-harmony-services">
              <div class="page-section-col">
                <div class="harmony-block">
                  <div class="harmony-block-top">
                    <h2 class="title-h2 anim-txt-focus">
                      {{ $t("titleHarmonySevices") }}
                    </h2>
                    <picture>
                  
                      <img
                        class="harmony-logo"
                        :src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-bb-studio-architecture-logo.jpg'
                        "
                        alt="entry-harmony-bb-studio-architecture-logo"
                        width="64"
                        height="64"
                      />
                    </picture>
                  </div>
                  <div class="harmony-block-bottom">
                    <div class="harmony-bottom-col anim-slide-left">
                      <div class="harmony-bottom-col-left">
                        <img
                          class="quote-before"
                          :src="
                            this.$pathprod +
                            'images/entrer_harmony/quote-before.svg'
                          "
                          alt="quote-before"
                          width="39"
                          height="25"
                        />
                      </div>
                      <div class="harmony-bottom-col-right">
                        <h4 class="title-h4">
                          {{ $t("dataHarmonyServices")["0"] }}
                        </h4>
                        <p class="harmony-text">
                          {{ $t("dataHarmonyServices")["1"] }}
                          <img
                            class="quote-after"
                            :src="
                              this.$pathprod +
                              'images/entrer_harmony/quote-after.svg'
                            "
                            alt="quote-after"
                            width="17"
                            height="11"
                          />
                        </p>
                      </div>
                    </div>
                    <div class="harmony-bottom-col anim-slide-right">
                      <div class="harmony-bottom-col-left">
                        <img
                          class="quote-before"
                          :src="
                            this.$pathprod +
                            'images/entrer_harmony/quote-before.svg'
                          "
                          alt="quote-before"
                          width="39"
                          height="25"
                        />
                      </div>
                      <div class="harmony-bottom-col-right">
                        <h4 class="title-h4">
                          {{ $t("dataHarmonyServices")["2"] }}
                        </h4>
                        <p class="harmony-text">
                          {{ $t("dataHarmonyServices")["3"] }}
                          <img
                            class="quote-after"
                            :src="
                              this.$pathprod +
                              'images/entrer_harmony/quote-after.svg'
                            "
                            alt="quote-after"
                            width="17"
                            height="11"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Section Harmony services-->
          </div>

          <div class="section">
            <!--Section brulerie-->
            <div
              class="page-section section-brulerie"
              @wheel.prevent="
                wheelIntersection(
                  $event,
                  section_brulerie,
                  section_harmony,
                  section_destination
                )
              "
            >
              <div class="page-section-col">
                <div class="block-image-zoom">
                  <div class="block-img block-image-transparent">
                    <picture class="loadTemp">
                   
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-brulerie.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-brulerie-retina.jpg 2x'
                        "
                        alt="entry-harmony-croquis-brulerie"
                        class="img-desk"
                        width="1920"
                        height="937"
                      />
                    </picture>
                    <picture class="loadTemp">
                   
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-brulerie-mobile.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-brulerie-mobile-retina.jpg 2x'
                        "
                        alt="entry-harmony-croquis-brulerie-mobile"
                        class="img-mobile"
                        width="375"
                        height="667"
                      />
                    </picture>
                  </div>
                  <div class="block-img block-image-full">
                    <picture class="loadTemp">
                  
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-Pers-Brulerie.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-Pers-Brulerie-retina.jpg 2x'
                        "
                        alt="entry-harmony-Pers-Brulerie"
                        width="1440"
                        height="900"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
            <!--Section brulerie-->
          </div>

          <div class="section">
            <!--Section destination-->
            <div
              class="page-section section-destination"
              @wheel="wheelIntersectionDestination"
            >
              <div class="page-section-col">
                <div class="block-destination">
                  <div class="block-top">
                    <h3 class="block-dest-title">
                      {{ $t("titleDestinationR") }}
                    </h3>
                  </div>
                  <div class="block-destination-bottom">
                    <div class="block-bottom-col">
                      <div class="dest-item">
                        <h4 class="title-h4-semi">
                          {{ $t("dataDestinationR")["0"] }} <br />
                          {{ $t("dataDestinationR")["1"] }}
                        </h4>
                      </div>
                      <div class="dest-item">
                        <h4 class="title-h4-semi">
                          {{ $t("dataDestinationR")["2"] }}
                        </h4>
                        <p>
                          {{ $t("dataDestinationR")["3"] }}
                        </p>
                      </div>
                      <div class="dest-item">
                        <h4 class="title-h4-semi">
                          {{ $t("dataDestinationR")["4"] }}
                          <span class="span-block">{{
                            $t("dataDestinationR")["5"]
                          }}</span>
                        </h4>
                        <p>
                          {{ $t("dataDestinationR")["6"] }}
                        </p>
                      </div>
                    </div>
                    <div class="block-bottom-col">
                      <div class="dest-item">
                        <h4 class="title-h4-semi">
                          {{ $t("dataDestinationR")["7"] }}
                          <span class="span-block">{{
                            $t("dataDestinationR")["8"]
                          }}</span>
                        </h4>
                        <p>
                          {{ $t("dataDestinationR")["9"] }}
                        </p>
                      </div>
                      <div class="dest-item">
                        <h4 class="title-h4-semi">
                          {{ $t("dataDestinationR")["10"] }}
                        </h4>
                        <p>
                          {{ $t("dataDestinationR")["11"] }}
                        </p>
                      </div>
                      <div class="dest-item">
                        <h4 class="title-h4-semi">
                          {{ $t("dataDestinationR")["12"] }}
                          <span>{{ $t("dataDestinationR")["13"] }}</span>
                        </h4>
                        <p>
                          {{ $t("dataDestinationR")["14"] }}
                        </p>
                      </div>
                      <div class="dest-item">
                        <h4 class="title-h4-semi">
                          {{ $t("dataDestinationR")["15"] }}
                        </h4>
                        <p>
                          {{ $t("dataDestinationR")["16"] }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Section destination-->
          </div>

          <div class="section">
            <!--Section cantine-->
            <div
              class="page-section section-cantine"
              @wheel.prevent="
                wheelIntersection(
                  $event,
                  section_cantine,
                  section_destination,
                  section_rdc
                )
              "
            >
              <div class="page-section-col">
                <div class="block-image-zoom">
                  <div class="block-img block-image-transparent">
                    <picture class="loadTemp">
                   
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-rie-la-grande-salle.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-rie-la-grande-salle-retina.jpg 2x'
                        "
                        alt="entry-harmony-croquis-rie-la-grande-salle"
                        class="img-desk-2"
                        width="1920"
                        height="937"
                      />
                    </picture>
                    <picture class="loadTemp">
                    
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-rie-la-grande-salle-mobile.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-croquis-rie-la-grande-salle-mobile.jpg'
                        "
                        alt="entry-harmony-croquis-rie-la-grande-salle"
                        class="img-desk-2-mobile"
                        width="375"
                        height="667"
                      />
                    </picture>
                  </div>
                  <div class="block-img block-image-full">
                    <picture class="loadTemp">
                  
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-pers-rie-la-grande-salle.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-pers-rie-la-grande-salle-retina.jpg 2x'
                        "
                        alt="entry-harmony-pers-rie-la-grande-salle"
                        width="1440"
                        height="900"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
            <!--Section cantine-->
          </div>

          <div class="section">
            <!-- Section Ongle RDC Ultra vivant & mezzanie -->
            <div class="page-section section-onglet-rdc">
              <div class="page-section-col">
                <div class="section-tab-content">
                  <ul class="tab-list">
                    <li v-for="tab in tabContenu" :key="tab.index">
                      <span
                        class="tab-link"
                        @click="changeTabContent(tab.id)"
                        >{{ tab.menu }}</span
                      >
                    </li>
                  </ul>
                  <div class="tab-details">
                    <div
                      class="tab-content"
                      v-for="tab in tabContenu"
                      :key="tab.id"
                    >
                      <div class="tab-block-title">
                        <h3 class="tab-title">
                          {{ $t("dataOnglet")[tab.id - 1] }}
                        </h3>
                      </div>
                      <div class="tab-img">
                        <picture>
                        
                          <img
                            :src="$t('OngletJpgDesktop')[tab.id - 1]"
                            :alt="tab.alt"
                            class="tab-img-desk"
                            width="1075"
                            height="792"
                          />
                        </picture>
                        <picture>
                       
                          <img
                            :src="$t('OngletJpgMobile')[tab.id - 1]"
                            :alt="tab.alt"
                            class="tab-img-mobile"
                            width="750"
                            height="951"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Section Ongle RDC Ultra vivant & mezzanie -->
          </div>

          <div class="section">
            <!--Section Vivre en harmony-->
            <div
              class="page-section section-vivre-harmony section-vivre-harmony-img-full"
              @wheel="wheelIntersectionVivreHarmony"
              id="harmony-vivre"
            >
              <div class="page-section-col">
                <div class="dynamique-images-block">
                  <div
                    class="dynamique-images-flou dynamic-images drop-attachment-fixed"
                  >
                    <picture class="loadTemp">
                   
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-bg-vivre-bis.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-bg-vivre-bis-retina.jpg 2x'
                        "
                        alt="entry-harmony-bg-vivre-bis"
                        width="1920"
                        height="937"
                      />
                    </picture>
                    <div class="block-gold-dynamique">
                      <p class="block-gold-text">
                        {{ $t("titleVivreHarmony") }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="dynamique-images-full dynamic-images drop-attachment-fixed"
                  >
                    <picture class="loadTemp">
                   
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-vivre-bg-2-bis.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-vivre-bg-2-bis-retina.jpg 2x'
                        "
                        alt="entry-harmony-vivre-bg-2-bis"
                        width="1920"
                        height="937"
                      />
                    </picture>
                  </div>

                  <div
                    class="block-key-number"
                    :class="{ increment: incrementV }"
                  >
                    <div class="block-key-top"></div>
                    <div class="block-key-bottom">
                      <div class="block-gold-number">
                        <div class="block-gold-top">
                          <h4 class="title-h4-black">
                            {{ $t("dataChiffreKeVH")["0"] }} <br />
                            {{ $t("dataChiffreKeVH")["1"] }}
                          </h4>
                        </div>
                        <div class="block-gold-bottom">
                          <div class="block-gold-number-col">
                            <div class="key-number-list">
                              <div class="key-number-item">
                                <p class="key-number white">
                                  {{ $t("dataChiffreKeVH")["2"]
                                  }}<br class="d-block" />
                                  {{ $t("dataChiffreKeVH")["3"] }}
                                </p>
                              </div>
                              <div class="key-number-item">
                                <p class="key-desc">
                                  {{ $t("dataChiffreKeVH")["4"] }}
                                </p>
                                <p class="key-number white">
                                  <!-- <span class="number-to-animate" data-number="1600">1 600</span>  -->
                                  <number
                                    ref="number5"
                                    :from="0"
                                    :to="1600"
                                    :format="theFormat"
                                    animationPaused
                                    :duration="1"
                                    easing="Power1.easeOut"
                                  />
                                  m<span class="m2">2</span>
                                </p>
                                <p class="key-desc">
                                  {{ $t("dataChiffreKeVH")["5"] }}
                                </p>
                              </div>

                              <div class="key-number-item">
                                <p class="key-number white">
                                  <!-- <span class="number-to-animate" data-number="2,70">2,70</span>  -->
                                  <number
                                    ref="number6"
                                    :from="0"
                                    :to="2.7"
                                    :format="theFormatDecimal"
                                    animationPaused
                                    :duration="2"
                                    easing="Power1.easeOut"
                                  />
                                  m
                                </p>
                                <p class="key-desc">
                                  {{ $t("dataChiffreKeVH")["6"] }}
                                </p>
                              </div>

                              <div class="key-number-item">
                                <p class="key-number white">
                                  <!-- <span class="number-to-animate" data-number="1,35">1,35</span> -->
                                  <number
                                    ref="number7"
                                    :from="0"
                                    :to="1.35"
                                    :format="theFormatDecimal"
                                    animationPaused
                                    :duration="2"
                                    easing="Power1.easeOut"
                                  />
                                  m
                                </p>
                                <p class="key-desc">
                                  {{ $t("dataChiffreKeVH")["7"] }}
                                </p>
                              </div>

                              <div class="key-number-item">
                                <p class="key-number white">
                                  <!-- <span class="number-to-animate" data-number="80">80</span>  -->
                                  <number
                                    ref="number8"
                                    :from="0"
                                    :to="80"
                                    :format="theFormat"
                                    animationPaused
                                    :duration="2"
                                    easing="Power1.easeOut"
                                  />
                                  %
                                </p>
                                <p class="key-desc">
                                  {{ $t("dataChiffreKeVH")["8"] }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="block-gold-number-col">
                            <div class="block-col-content">
                              <table class="table-dynamic">
                                <thead>
                                  <tr>
                                    <th>{{ $t("dataTableVH")["0"] }}</th>
                                    <th class="tac">SUB*</th>
                                    <th class="tar">
                                      {{ $t("dataTableVH")["1"] }} <br />
                                      {{ $t("dataTableVH")["2"] }}
                                    </th>
                                    <th class="tar">
                                      {{ $t("dataTableVH")["3"] }} <br />{{
                                        $t("dataTableVH")["4"]
                                      }}
                                    </th>
                                    <th class="tac">SUBL</th>
                                    <th class="tar">
                                      {{ $t("dataTableVH")["5"] }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="lignTable in tableVivre"
                                    :key="lignTable.id"
                                  >
                                    <td>{{ lignTable.sub }}</td>
                                    <td class="tac">
                                      {{ lignTable.subNumber }}
                                      <span v-if="lignTable.subNumber"
                                        >m<span class="m2">2</span></span
                                      >
                                    </td>
                                    <td class="tar">
                                      {{ lignTable.pers }}
                                      <span v-if="lignTable.pers">pers</span>
                                    </td>
                                    <td class="tar">
                                      {{ lignTable.service }}
                                      <span v-if="lignTable.service"
                                        >m<span class="m2">2</span></span
                                      >
                                    </td>
                                    <td class="tac">
                                      {{ lignTable.subl }}
                                      <span v-if="lignTable.subl"
                                        >m<span class="m2">2</span></span
                                      >
                                    </td>
                                    <td class="tar">
                                      {{ lignTable.surfaces }}
                                      <span v-if="lignTable.surfaces"
                                        >m<span class="m2">2</span></span
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td>TOTAL</td>
                                    <td class="tac">
                                      10 885 m<span class="m2">2</span>
                                    </td>
                                    <td class="tar">1 310 pers.</td>
                                    <td class="tar">
                                      2 149 m<span class="m2">2</span>
                                    </td>
                                    <td class="tar">
                                      13 034 m<span class="m2">2</span>
                                    </td>
                                    <td class="tar">
                                      2 826 m<span class="m2">2</span>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>

                              <span class="indice-table"
                                >(*) {{ $t("dataTableVH")["6"] }}</span
                              >
                            </div>
                            <picture class="loadTemp">
                           
                              <img
                                src=""
                                :dta-src="
                                  this.$pathprod +
                                  'images/entrer_harmony/entry-harmony-building.png'
                                "
                                srcset=""
                                :dta-srcset="
                                  this.$pathprod +
                                  'images/entrer_harmony/entry-harmony-building.png'
                                "
                                alt="entry-harmony-building"
                                class="building-img"
                                width="201"
                                height="814"
                              />
                            </picture>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Section Vivre en harmony-->
          </div>

          <div
            class="section"
            @wheel.prevent="
              wheelIntersectionCarousel(
                $event,
                slider_block_carousel2,
                vivre_harmony,
                section_amenagement
              )
            "
          >
            <!--Section carousel 2-->
            <div class="page-section section-carousel section-carousel2">
              <div class="page-section-col slider-block-carousel2">
                <div
                  class="slide slider-item"
                  v-for="carousel in carousel2"
                  :key="carousel.id"
                  :data-anchor="'slideCarousel2' + carousel.id"
                >
                  <picture>
                    
                    <img
                      :src="carousel.src"
                      :srcset="carousel.srcset"
                      :alt="carousel.alt"
                      @click="openLightbox2(carousel.id, lightbox2)"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <!--Section carousel 2-->
          </div>

          <div class="section">
            <!-- Section aménagement dynamique -->
            <div class="page-section section-amenagement-dynamique">
              <div class="page-section-col">
                <div class="block-amenagement-dynamique">
                  <div class="block-top-amenagement">
                    <div class="block-top-title">
                      <h4 class="title-h2 anim-txt-focus">
                        {{ $t("titleAmenagementDynamique")["0"] }}
                        <strong>{{
                          $t("titleAmenagementDynamique")["1"]
                        }}</strong>
                      </h4>
                    </div>
                    <div class="block-title-r4">
                      <span class="block-r4-text">{{
                        $t("rAmenagement")
                      }}</span>
                    </div>
                  </div>
                  <div class="block-bottom-amenagement">
                    <div class="block-bottom-col">
                      <ul class="list-arrow list-black">
                        <li>
                          {{ $t("dataAmenagementDynamique")["0"] }}
                          <strong>1 918 m<sup class="m2">2</sup>*</strong>
                        </li>
                        <li>
                          {{ $t("dataAmenagementDynamique")["1"] }}
                          <strong
                            >167
                            {{ $t("dataAmenagementDynamique")["2"] }}</strong
                          >
                        </li>
                        <li>
                          <strong>{{
                            $t("dataAmenagementDynamique")["3"]
                          }}</strong>
                          <br />{{ $t("dataAmenagementDynamique")["4"] }}
                        </li>
                        <li>
                          {{ $t("dataAmenagementDynamique")["5"] }}
                          <br /><strong>8,8 m<span class="m2">2</span></strong>
                        </li>
                        <li>
                          {{ $t("dataAmenagementDynamique")["6"] }}
                          <strong>7,3 m<span class="m2">2</span></strong>
                        </li>
                        <li>
                          {{ $t("dataAmenagementDynamique")["7"] }}
                          <strong>1 866 m<span class="m2">2</span></strong>
                        </li>
                        <li>
                          {{ $t("dataAmenagementDynamique")["8"] }}
                          <strong>18,5 m</strong>
                        </li>
                      </ul>
                      <p class="indice-text">
                        (*) {{ $t("dataAmenagementDynamique")["9"] }}
                      </p>
                    </div>
                    <div class="block-bottom-col">
                      <picture class="loadTemp">
                        
                        <img
                          src=""
                          :dta-src="
                            this.$pathprod +
                            'images/entrer_harmony/entry-harmony-amenagement-dynamique.jpg'
                          "
                          srcset=""
                          :dta-srcset="
                            this.$pathprod +
                            'images/entrer_harmony/entry-harmony-amenagement-dynamique.jpg x2'
                          "
                          alt="entry-harmony-amenagement-dynamique"
                          width="1118"
                          height="641"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Section aménagement dynamique -->
          </div>

          <div class="section">
            <!--Section Roof ToP-->
            <div
              id="top-roof"
              class="page-section section-rooftop section-rooftop-img-full"
              @wheel="wheelIntersectionRoofTop"
            >
              <div class="page-section-col">
                <div class="dynamique-images-block">
                  <div
                    class="dynamique-images-flou dynamic-images drop-attachment-fixed"
                  >
                    <picture class="loadTemp">
                      
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-rooftop1.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-rooftop1-retina.jpg 2x'
                        "
                        alt="entry-harmony-rooftop1"
                        width="1920"
                        height="937"
                      />
                    </picture>
                    <div class="block-gold-dynamique">
                      <p class="block-gold-text">{{ $t("titleRooftop") }}</p>
                    </div>
                  </div>

                  <div class="dynamique-images-full dynamic-images drop-attachment-fixed">
                    <picture class="loadTemp">
                      
                      <img
                        src=""
                        :dta-src="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-rooftop2-bis.jpg'
                        "
                        srcset=""
                        :dta-srcset="
                          this.$pathprod +
                          'images/entrer_harmony/entry-harmony-rooftop2-bis-retina.jpg 2x'
                        "
                        alt="entry-harmony-rooftop2-bis"
                        width="1920"
                        height="937"
                      />
                    </picture>
                  </div>

                  <div class="block-key-number" :class="{ increment: incrementR }">
                    <div class="block-key-top"></div>
                    <div class="block-key-bottom">
                      <div class="block-gold-number">
                        <div class="block-gold-item">
                          <h4 class="title-h4-black">
                            {{ $t("dataRooftop")["0"] }}
                          </h4>
                        </div>
                        <div class="block-gold-item">
                          <picture class="loadTemp">
                          
                            <img
                              src=""
                              :dta-src="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-plan-outdoor.png'
                              "
                              srcset=""
                              :dta-srcset="
                                this.$pathprod +
                                'images/entrer_harmony/entry-harmony-plan-outdoor.png 2x'
                              "
                              alt="entry-harmony-plan-outdoor"
                              class="img-plan"
                              width="1150"
                              height="714"
                            />
                          </picture>
                        </div>
                        <div class="block-gold-item">
                          <h4 class="title-h4-black">
                            2 800 m<span class="m2-custom">2</span>&nbsp;
                            {{ $t("dataRooftop")["1"] }}
                          </h4>
                          <ul class="list-arrow list-arrow-custom">
                            <li>{{ $t("dataRooftop")["2"] }}</li>
                            <li>{{ $t("dataRooftop")["3"] }}</li>
                            <li>{{ $t("dataRooftop")["4"] }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Section Roof ToP-->
          </div>

          <div class="section" @wheel.prevent=" wheelIntersectionCarousel($event, slider_block_carousel3, section_rooftop, null)">
            <!--Section carousel 3-->
            <div class="page-section section-carousel section-carousel3">
              <div class="page-section-col slider-block-carousel3">
                <div
                  class="slide slider-item"
                  v-for="carousel in carousel3"
                  :key="carousel.id"
                  :data-anchor="'slideCarousel3' + carousel.id"
                >
                  <picture>
                    
                    <img
                      :src="carousel.src"
                      :srcset="carousel.srcset"
                      :alt="carousel.alt"
                      @click="openLightbox3(carousel.id, lightbox3)"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <!--Section carousel 3-->
          </div>
        </full-page>

        <!--LightBox Carousel 1-->
        <div class="lightbox lightbox-1" :class="{ show: showLightbox }">
          <a
            href="#"
            aria-label="close lightbox"
            class="btn btn-white btn-close-lightbox"
            @click="closeLightbox"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364"
                stroke="#F3C678"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <div class="lightbox-slider" v-show="showLightbox">
            <div
              class="lightbox-item"
              v-for="carousel in carousel1"
              :key="carousel.id"
              :class="{ active: activeImage }"
              :data-item="carousel.id"
            >
              <picture>
                
                <img
                  :srcset="carousel.srcset"
                  :src="carousel.src"
                  :alt="carousel.alt"
                  width="1920"
                  height="937"
                />
              </picture>
              <div class="legend-block">
                <p class="legend-text">
                  <strong>{{
                    $t("DataLightboxLegend1")[carousel.id - 1]
                  }}</strong>
                  {{ $t("DataLightboxLegendSubNumber1")[carousel.id - 1]
                  }}<sup>{{
                    $t("DataLightboxLegendSubIndice1")[carousel.id - 1]
                  }}</sup>
                  <span>{{
                    $t("DataLightboxLegendSubTxt1")[carousel.id - 1]
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="nav-lightbox">
            <a
              href="#"
              aria-label="prev lightbox"
              class="btn btn-white btn-nav-lightbox btn-l-prev"
              @click.prevent="goToprev(lightbox1)"
              :data-postion-active="priority"
              :class="{ disabled: disabled_prev }"
            >
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <a
              href="#"
              aria-label="next lightbox"
              class="btn btn-white btn-nav-lightbox btn-l-next"
              @click.prevent="goTonext(lightbox1)"
              :data-postion-active="priority"
              :class="{ disabled: disabled_next }"
            >
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
        <!--LightBox Carousel 1-->

        <!--LightBox Carousel 2-->
        <div class="lightbox lightbox-2" :class="{ show: showLightbox2 }">
          <a
            href="#"
            aria-label="close lightbox"
            class="btn btn-white btn-close-lightbox"
            @click="closeLightbox2"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364"
                stroke="#F3C678"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <div class="lightbox-slider" v-show="showLightbox2">
            <div
              class="lightbox-item"
              v-for="carousel in carousel2"
              :key="carousel.id"
              :class="{ active: activeImage }"
              :data-item="carousel.id"
            >
              <picture>
                
                <img
                  :src="carousel.src"
                  :srcset="carousel.srcset"
                  :alt="carousel.alt"
                  width="1930"
                  height="937"
                />
              </picture>
              <div class="legend-block">
                <p class="legend-text">
                  <strong>{{
                    $t("DataLightboxLegend2")[carousel.id - 1]
                  }}</strong>
                  {{ $t("DataLightboxLegendSubNumber2")[carousel.id - 1]
                  }}<sup>{{
                    $t("DataLightboxLegendSubIndice2")[carousel.id - 1]
                  }}</sup>
                  <span>{{
                    $t("DataLightboxLegendSubTxt1")[carousel.id - 1]
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="nav-lightbox">
            <a
              href="#"
              class="btn btn-white btn-nav-lightbox btn-l-prev"
              @click.prevent="goToprev(lightbox2)"
              :data-postion-active="priority2"
              :class="{ disabled: disabled_prev }"
            >
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <a
              href="#"
              class="btn btn-white btn-nav-lightbox btn-l-next"
              @click.prevent="goTonext(lightbox2)"
              :data-postion-active="priority2"
              :class="{ disabled: disabled_next }"
            >
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
        <!--LightBox Carousel 2-->

        <!--LightBox Carousel 3-->
        <div class="lightbox lightbox-3" :class="{ show: showLightbox3 }">
          <a
            href="#"
            aria-label="close lightbox"
            class="btn btn-white btn-close-lightbox"
            @click="closeLightbox3"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364"
                stroke="#F3C678"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <div class="lightbox-slider" v-show="showLightbox3">
            <div
              class="lightbox-item"
              v-for="carousel in carousel3"
              :key="carousel.id"
              :class="{ active: activeImage }"
              :data-item="carousel.id"
            >
              <picture>
                <img
                  :src="carousel.src"
                  :srcset="carousel.srcset"
                  :alt="carousel.alt"
                  width="1930"
                  height="937"
                />
              </picture>
              <div class="legend-block">
                <p class="legend-text">
                  <strong>{{$t("DataLightboxLegend3")[carousel.id - 1]}}</strong>
                  {{ $t("DataLightboxLegendSubNumber3")[carousel.id - 1]}}<sup>{{$t("DataLightboxLegendSubIndice3")[carousel.id - 1]}}</sup>
                  <span>{{$t("DataLightboxLegendSubTxt3")[carousel.id - 1]}}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="nav-lightbox">
            <a
              href="#"
              class="btn btn-white btn-nav-lightbox btn-l-prev"
              @click.prevent="goToprev(lightbox3)"
              :data-postion-active="priority3"
              :class="{ disabled: disabled_prev }"
            >
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <a
              href="#"
              class="btn btn-white btn-nav-lightbox btn-l-next"
              @click.prevent="goTonext(lightbox3)"
              :data-postion-active="priority3"
              :class="{ disabled: disabled_next }"
            >
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.799 1L10.8995 10.8995L1 1"
                  stroke="#F3C678"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
        <!--LightBox Carousel 3-->
      </div>
    </div>
    <div v-else>
      <EntrerHarmonyMobile />
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper";

import "hooper/dist/hooper.css";

const arrayHabill = [
  {
    id: 1,
    description: "Volume initial",
    img: "/images/entrer_harmony/entry-harmony-architecture-1.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-1.svg",
    alt: "entry-harmony-architecture-1",
    data_sct: "sect1",
  },
  {
    id: 2,
    description: "Grandes loggias",
    img: "/images/entrer_harmony/entry-harmony-architecture-2.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-2.svg",
    alt: "entry-harmony-architecture-2",
    data_sct: "sect2",
  },
  {
    id: 3,
    description: "Jardins suspendus",
    img: "/images/entrer_harmony/entry-harmony-architecture-3.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-3.svg",
    alt: "entry-harmony-architecture-3",
    data_sct: "sect3",
  },
  {
    id: 4,
    description: "Habillage \nen lames champagne",
    img: "/images/entrer_harmony/entry-harmony-architecture-4.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-4.svg",
    alt: "entry-harmony-architecture-4",
    data_sct: "sect4",
  },
  {
    id: 5,
    description: "Ajustement des lames",
    img: "/images/entrer_harmony/entry-harmony-architecture-5.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-5.svg",
    alt: "entry-harmony-architecture-5",
    data_sct: "sect5",
  },
];

const arrayHabillInfo = [
  {
    id: 1,
    img: "/images/entrer_harmony/entry-harmony-architecture-1.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-1.svg",
    alt: "entry-harmony-architecture-1",
    data_img: "sect1",
  },
  {
    id: 2,
    img: "/images/entrer_harmony/entry-harmony-architecture-2.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-2.svg",
    alt: "entry-harmony-architecture-2",
    data_img: "sect2",
  },
  {
    id: 3,
    img: "/images/entrer_harmony/entry-harmony-architecture-3.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-3.svg",
    alt: "entry-harmony-architecture-3",
    data_img: "sect3",
  },
  {
    id: 4,
    img: "/images/entrer_harmony/entry-harmony-architecture-4.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-4.svg",
    alt: "entry-harmony-architecture-4",
    data_img: "sect4",
  },
  {
    id: 5,
    img: "/images/entrer_harmony/entry-harmony-architecture-5.svg",
    srcset: "/images/entrer_harmony/entry-harmony-architecture-5.svg",
    alt: "entry-harmony-architecture-5",
    data_img: "sect5",
  },
];
const arrayCarousel1 = [
  {
    id: 1,
    src: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-01-V2-Hall-Lobby-05.jpg",
    srcset: "/images/entrer_harmony/carousel/Interface-Bureaux01-retina.jpg 2x",
    srcsetWebp:
      "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-01-V2-Hall-Lobby-05.webp",
    alt: "Le Lobby",
    legend: "Le Lobby /",
    legendSub: "RDC",
  },
  {
    id: 2,
    src: "/images/entrer_harmony/entry-harmony-Pers-Brulerie.jpg",
    srcset: "/images/entrer_harmony/entry-harmony-Pers-Brulerie-retina.jpg 2x",
    srcsetWebp: "/images/entrer_harmony/entry-harmony-Pers-Brulerie.webp",
    alt: "La Brulerie",
    legend: "La Brulerie /",
    legendSub: "RDC",
  },
  {
    id: 3,
    src: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-05.jpg",
    srcset: "/images/entrer_harmony/carousel/Interface-Bureaux03-retina.jpg 2x",
    srcsetWebp:
      "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-05.webp",
    alt: "La Cuisine des Chefs",
    legend: "La Cuisine des Chefs /",
    legendSub: "RDC",
  },
  {
    id: 4,
    src: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-04.jpg",
    srcset: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-04-retina.jpg 2x",
    srcsetWebp:
      "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-04.webp",
    alt: "La Grande Salle",
    legend: "La Grande Salle /",
    legendSub: "RDC",
  },
  {
    id: 5,
    src: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-1.jpg",
    srcset: "/images/entrer_harmony/carousel/Interface-Bureaux05-retina.jpg 2x",
    srcsetWebp:
      "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-1.webp",
    alt: "La Mezzanine",
    legend: "La Mezzanine/",
    legendSub: "R+1",
  },
  {
    id: 6,
    src: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-07.jpg",
    srcset: "/images/entrer_harmony/carousel/Interface-Bureaux06-retina.jpg 2x",
    srcsetWebp:
      "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-07.webp",
    alt: "Le Rooftop",
    legend: "Le Rooftop /",
    legendSub: "R+8",
  },
];

const contentTable = [
  {
    sub: "R+9",
    subNumber: "",
    pers: "",
    service: "",
    subl: "",
    surfaces: "178",
  },
  {
    sub: "R+8",
    subNumber: "",
    pers: "",
    service: "",
    subl: "",
    surfaces: "1 368",
  },
  {
    sub: "R+7",
    subNumber: "1 433",
    pers: "166",
    service: "289",
    subl: "1 722",
    surfaces: "152",
  },
  {
    sub: "R+6",
    subNumber: "1 588",
    pers: "166",
    service: "317",
    subl: "1 905",
    surfaces: "27",
  },
  {
    sub: "R+5",
    subNumber: "1 553",
    pers: "167",
    service: "312",
    subl: "1 865",
    surfaces: "",
  },
  {
    sub: "R+4",
    subNumber: "1 553",
    pers: "167",
    service: "312",
    subl: "1 866",
    surfaces: "56",
  },
  {
    sub: "R+3",
    subNumber: "1 587",
    pers: "167",
    service: "317",
    subl: "1 904",
    surfaces: "32",
  },
  {
    sub: "R+2",
    subNumber: "1 621",
    pers: "167",
    service: "322",
    subl: "1 943",
    surfaces: "26",
  },
  {
    sub: "R+1",
    subNumber: "1 550",
    pers: "300",
    service: "280",
    subl: "1 830",
    surfaces: "318",
  },
  {
    sub: "RDC/Mezz",
    subNumber: "",
    pers: "10",
    service: "",
    subl: "",
    surfaces: "669",
  },
];

const arrayCarousel2 = [
  {
    id: 1,
    src: "/images/entrer_harmony/carousel/entry-harmony-Hall-Lobby-05.jpg",
    srcsetWebp:
      "/images/entrer_harmony/carousel/entry-harmony-Hall-Lobby-05.webp",
    srcset: "/images/entrer_harmony/carousel/entry-harmony-Hall-Lobby-05.jpg",
    alt: "Les Bureaux/R+41",
    legend: "Les Bureaux /",
    legendSub: "R+4",
  },
  {
    id: 2,
    src: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-02.jpg",
    srcsetWebp:
      "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-02.webp",
    srcset: "/images/entrer_harmony/carousel/Interface-Bureaux-Paris-02-retina.jpg 2x",
    alt: "Les Bureaux/R+42",
    legend: "Les Bureaux /",
    legendSub: "R+4",
  },
];

const arrayCarousel3 = [
  {
    id: 1,
    src: "/images/entrer_harmony/carousel/Interface-terrasse-caroussel3.jpg",
    srcset: "/images/entrer_harmony/carousel/Interface-terrasse-caroussel3.jpg",
    alt: "Interface-terrasse-caroussel3",
    legend: "Les Bureaux /",
    legendSub: "R+4",
  },
  {
    id: 2,
    src: "/images/entrer_harmony/carousel/Interface-topVue-caroussel3.jpg",
    srcset: "/images/entrer_harmony/carousel/Interface-topVue-caroussel3.jpg",
    alt: "Interface-Bureaux-Paris-Brulerie",
    legend: "Les Bureaux /",
    legendSub: "R+4",
  },
];

const tabContent = [
  {
    id: 1,
    active: true,
    menu: "RDC",
    src: "/images/entrer_harmony/entry-harmony-ultra-vivant.jpg",
    srcMobile: "/images/entrer_harmony/entry-harmony-ultra-vivant-mobile.jpg",
    srcsetWebpD: "/images/entrer_harmony/entry-harmony-ultra-vivant.webp",
    srcsetWebpM:
      "/images/entrer_harmony/entry-harmony-ultra-vivant-mobile.webp",
    alt: "entry-harmony-ultra-vivant",
    legend: "RDC ultra vivant",
  },
  {
    id: 2,
    active: false,
    menu: "Mezzanine",
    src: "/images/entrer_harmony/entry-harmony-mezzanine-terasse.jpg",
    srcMobile:
      "/images/entrer_harmony/entry-harmony-mezzanine-terasse-mobile.jpg",
    srcsetWebpD: "/images/entrer_harmony/entry-harmony-mezzanine-terasse.webp",
    srcsetWebpM:
      "/images/entrer_harmony/entry-harmony-ultra-vivant-mobile.webp",
    alt: "entry-harmony-mezzanine-terasse",
    legend: "VUE SUR \n MEZZANINE \n Terrasse",
  },
];

export default {
  name: "EntrerHarmony",
  components: {
    Hooper,
    Slide,
    EntrerHarmonyMobile: () => import("@/components/EntrerHarmonyMobile.vue"),
    VideoEntrerHarmony: () =>
      import("@/components/video/VideoEntrerHarmony.vue"),
  },
  props: {
    msg: String,
  },
  data() {
    return {
      init: 100,
      init2: 100,
      init3: 100,
      initCalc1: 350,
      initCalc2: 350,
      initCalc3: 350,
      carouselData: 0,
      componentKey: 0,
      carousel1: arrayCarousel1,
      carousel2: arrayCarousel2,
      carousel3: arrayCarousel3,
      info_habillages: arrayHabill,
      incrementE: false,
      incrementV: false,
      incrementR: false,
      showBrushK: false,
      showLightbox: false,
      showLightbox2: false,
      showLightbox3: false,
      activeImage: false,
      priority: 1,
      priority2: 1,
      priority3: 1,
      habillages_Img: arrayHabillInfo,
      tableVivre: contentTable,
      tabActive: false,
      tabContentActive: false,
      tabContenu: tabContent,
      interupteur: false,
      hooperSettings: {
        itemsToShow: 1.25,
        centerMode: true,
        breakpoints: {
          320: {
            centerMode: true,
            itemsToShow: 1.25,
          },
          992: {
            itemsToShow: 1.25,
          },
        },
      },
      hooperSettingsMain: {
        itemsToShow: 1,
        transition: 800,
        vertical: true,
        wheelControl: false,
        touchDrag: false,
        shortDrag: false,
        breakpoints: {
          320: {
            itemsToShow: 1,
          },
          992: {
            itemsToShow: 1,
          },
        },
      },
      hooperSettingsMainHabillage: {
        itemsToShow: 1,
        transition: 1500,
        vertical: true,
        touchDrag: false,
        shortDrag: false,
        trimWhiteSpace: true,
      },
      options: {
        licenseKey: "YOUR_KEY_HEERE",
        scrollingSpeed: 1500,
        menu: "#menuPage",
        anchors: [
          "section-first",
          "section-image",
          "precis-geste",
          "section-habillage",
          "section-facade",
          "dynamique-services",
          "section-carousel-1",
          "section-harmony",
          "section-brulerie",
          "section-destination",
          "section-cantine",
          "section-rdc",
          "vivre-harmony",
          "section-carousel-2",
          "section-amenagement",
          "roof-top",
          "section-carousel-3",
        ],
        touchSensitivity: 1,
        autoScrolling: true,
        scrollHorizontally: true,
        navigation: true,
        slidesNavigation: true,
        navigationPosition: "right",
      },
      oldValueD: 0,
      newValueD: 0,
      AnimtxtBlur: false,
      lightbox1: ".lightbox-1",
      lightbox2: ".lightbox-2",
      lightbox3: ".lightbox-3",
      isMove: false,
      /** carrousel */
      slider_block_carousel1: ".slider-block-carousel1",
      slider_block_carousel2: ".slider-block-carousel2",
      slider_block_carousel3: ".slider-block-carousel3",
      dynamique_services: "dynamique-services",
      section_harmony: "section-harmony",
      vivre_harmony: "vivre-harmony",
      /** */
      section_amenagement: "section-amenagement",
      section_rooftop: "roof-top",
      section_brulerie: ".section-brulerie",
      section_destination: "section-destination",
      section_cantine: ".section-cantine",
      section_rdc: "section-rdc",
      section_dynamique_img_full: ".section-dynamique-img-full",
      section_facade: "section-facade",
      section_carousel1: "section-carousel-1",
      section_vivre_harmony_img_full: ".section-vivre-harmony-img-full",
      section_carousel_2: "section-carousel-2",
      section_rooftop_img_full: ".section-rooftop-img-full",
      disabled_next: false,
      disabled_prev: false,
      bottomPos_gestPrecis: null,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    theFormat(number) {
      return this.numberWithSpaces(number.toFixed(0));
    },

    theFormatDecimal(number) {
      return number.toFixed(2);
    },

    playAnimation() {
      this.$refs.number1.play();
      this.$refs.number2.play();
      this.$refs.number3.play();
      this.$refs.number4.play();
    },

    playAnimationVivre() {
      this.$refs.number5.play();
      this.$refs.number6.play();
      this.$refs.number7.play();
      this.$refs.number8.play();
    },

    isMobile() {
      if (screen.width < 1200) {
        return true;
      } else {
        return false;
      }
    },

    handleresize() {
      this.componentKey += 1;
      this.isMobile();
    },

    initOnglet() {
      const tabListItem = document.querySelectorAll(".tab-list li");
      const tabDetailsContent = document.querySelectorAll(
        ".tab-details .tab-content"
      );
      tabListItem[0].classList.add("active");
      tabDetailsContent[0].classList.add("active");
    },

    changeTabContent(id) {
      const tabListItem = document.querySelectorAll(".tab-list li");
      const tabDetailsContent = document.querySelectorAll(
        ".tab-details .tab-content"
      );
      // this.tabContenu[id - 1].active = true;
      for (let i = 0; i < tabListItem.length; i++) {
        tabListItem[i].classList.remove("active");
        tabDetailsContent[i].classList.remove("active");
      }
      tabListItem[id - 1].classList.add("active");
      tabDetailsContent[id - 1].classList.add("active");
    },

    /*fonction get data number element*/
    getDataNumberElement(itemLightBox) {
      let idr = 0;
      for (var i = 0, len = itemLightBox.length; i < len; i++) {
        if (itemLightBox[i].classList.contains("active")) {
          idr = parseInt(itemLightBox[i].getAttribute("data-item"));
        }
      }
      return idr;
    },

    scrollToNext(e) {
      e.preventDefault();
      this.$refs.fullpage.api.moveTo("section-image");
    },

    addFormatNumber(el) {
      let item = document.querySelectorAll(el);
      for (let i = 0; i < item.length; i++) {
        item[i].innerText = this.numberWithSpaces(item[i].innerText);
      }
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    /*Fonction beginAnimationBrush pour la section 3*/
    beginAnimationBrushKeyNumber() {
      this.showBrushK = true;
    },

    /******************************* */
    /***    LIGHT BOX CAROUSSEL  *****/
    /******************************* */

    /*Prev lightbox item*/
    goToprev(lightbox) {
      let itemLightBox = document.querySelectorAll(
        lightbox + " .lightbox-item"
      );
      let nbitem = itemLightBox.length;
      let idActuel = this.getDataNumberElement(itemLightBox);

      if (idActuel <= nbitem && idActuel > 1) {
        itemLightBox[idActuel - 1].classList.remove("active");
        itemLightBox[idActuel - 2].classList.add("active");
        this.priority = idActuel - 1;
      }

      this.disabled_prev = idActuel - 1 === 1;
      this.disabled_next = idActuel - 1 === nbitem;
    },

    /*Next lightbox item*/
    goTonext(lightbox) {
      let itemLightBox = document.querySelectorAll(
        lightbox + " .lightbox-item"
      );
      let nbitem = itemLightBox.length;
      let idActuel = this.getDataNumberElement(itemLightBox);
      if (idActuel < nbitem) {
        itemLightBox[idActuel - 1].classList.remove("active");
        itemLightBox[idActuel].classList.add("active");
        this.priority = idActuel + 1;
      }

      this.disabled_next = idActuel + 1 === nbitem;
      this.disabled_prev = idActuel + 1 === 1;
    },

    check_LightBoxBtn(lightbox) {
      /**** CHECK BTN PREV */
      let check_LightBox = document.querySelectorAll(
        lightbox + " .lightbox-item"
      );
      let nbitem = check_LightBox.length;
      let idActuel = this.getDataNumberElement(check_LightBox);
      this.disabled_prev = idActuel === 1;
      this.disabled_next = idActuel === nbitem;
    },

    /*Fonction openLightBox*/
    openLightbox(id, lightbox) {
      this.showLightbox = true;
      document.body.classList.add("open-menu");
      let itemLightBox = document.querySelectorAll(".lightbox-item");
      for (var i = 0, len = itemLightBox.length; i < len; i++) {
        if (parseInt(itemLightBox[i].getAttribute("data-item")) == id) {
          itemLightBox[i].classList.add("active");
          document
            .querySelector(".btn-l-prev")
            .setAttribute("data-postion-active", id);
          document
            .querySelector(".btn-l-next")
            .setAttribute("data-postion-active", id);
          this.priority = id;
        }
      }
      this.check_LightBoxBtn(lightbox);
    },

    /*Fonction closeLightbox*/
    closeLightbox(e) {
      e.preventDefault();
      this.showLightbox = false;
      document.body.classList.remove("open-menu");
      let itemLightBox = document.querySelectorAll(".lightbox-item");
      for (var i = 0, len = itemLightBox.length; i < len; i++) {
        itemLightBox[i].classList.remove("active");
      }
      this.priority = 1;
    },

    /*Fonction openLightBox*/
    openLightbox2(id, lightbox) {
      this.showLightbox2 = true;
      document.body.classList.add("open-menu");
      let itemLightBox = document.querySelectorAll(".lightbox-item");
      for (var i = 0, len = itemLightBox.length; i < len; i++) {
        if (parseInt(itemLightBox[i].getAttribute("data-item")) == id) {
          itemLightBox[i].classList.add("active");
          document
            .querySelector(".btn-l-prev")
            .setAttribute("data-postion-active", id);
          document
            .querySelector(".btn-l-next")
            .setAttribute("data-postion-active", id);
          this.priority2 = id;
        }
      }
      this.check_LightBoxBtn(lightbox);
    },

    /*Fonction openLightBox*/
    openLightbox3(id, lightbox) {
      this.showLightbox3 = true;
      document.body.classList.add("open-menu");
      let itemLightBox = document.querySelectorAll(".lightbox-item");
      for (var i = 0, len = itemLightBox.length; i < len; i++) {
        if (parseInt(itemLightBox[i].getAttribute("data-item")) == id) {
          itemLightBox[i].classList.add("active");
          document
            .querySelector(".btn-l-prev")
            .setAttribute("data-postion-active", id);
          document
            .querySelector(".btn-l-next")
            .setAttribute("data-postion-active", id);
          this.priority3 = id;
        }
      }
      this.check_LightBoxBtn(lightbox);
    },

    /*Fonction closeLightbox*/
    closeLightbox2(e) {
      e.preventDefault();
      this.showLightbox2 = false;
      document.body.classList.remove("open-menu");
      let itemLightBox = document.querySelectorAll(".lightbox-item");
      for (var i = 0, len = itemLightBox.length; i < len; i++) {
        itemLightBox[i].classList.remove("active");
      }
      this.priority2 = 1;
    },

    /*Fonction closeLightbox*/
    closeLightbox3(e) {
      e.preventDefault();
      this.showLightbox3 = false;
      document.body.classList.remove("open-menu");

      let itemLightBox = document.querySelectorAll(".lightbox-item");
      for (var i = 0, len = itemLightBox.length; i < len; i++) {
        itemLightBox[i].classList.remove("active");
      }
      this.priority3 = 1;
    },

    /*--------------(    END     )--------------------*/

    stopBlockPropagation(e) {
      e.stopPropagation();
    },

    beforeAfterBloc() {
      this.interupteur = true;
      setTimeout(() => {
        this.interupteur = false;
      }, 2000);
    },
    wheelIntersectionGestePrecis(e) {
      e.stopPropagation();
      /**---------   INTERACTION IMG SECTION HABILLAGE     ---------- */
      var habillage_bloc = document.querySelectorAll(".slider-block-habillage li");
      var habillage_img = document.querySelectorAll(".sct-left .img-block");

      for (var i = 0; i <= habillage_bloc.length - 1; i++) {
        habillage_bloc[i].classList.contains("is-active") ? habillage_img[i].classList.add("openImg") : habillage_img[i].classList.remove("openImg");
      }
      /**---------    END     -------------- */
      let keyNumberBlock = document.querySelector(".section-geste-precis");
      var check_next = keyNumberBlock.scrollTop

      if (this.isMove) {
        return false;
      } else {
        if (e.deltaY < 0) {
          this.isWheel();
          if (keyNumberBlock.scrollTop === 0) {
            this.$refs.fullpage.api.moveTo("section-image");
          }
        } else {
          this.isWheel();
          var screen_height = Math.floor(keyNumberBlock.scrollHeight - keyNumberBlock.offsetHeight)
          var old_value = Math.floor(check_next)
          var tmp_value = old_value
          old_value = this.bottomPos_gestPrecis
          this.bottomPos_gestPrecis = tmp_value

          if(old_value == this.bottomPos_gestPrecis || this.bottomPos_gestPrecis == screen_height){
              this.$refs.fullpage.api.moveTo("section-habillage");
          }
        }
      }
    },

    wheelIntersectionHabillage(e) {
      e.stopPropagation();
      /**---------   INTERACTION IMG SECTION HABILLAGE     ---------- */
      var habillage_bloc = document.querySelectorAll(".slider-block-habillage li");
      var habillage_img = document.querySelectorAll(".sct-left .img-block");

      for (var i = 0; i <= habillage_bloc.length - 1; i++) {
        habillage_bloc[i].classList.contains("is-active") ? habillage_img[i].classList.add("openImg") : habillage_img[i].classList.remove("openImg");
      }
      /**---------    END     -------------- */
      if (this.isMove) {
        return false;
      } else {
        if (e.deltaY < 0) {
          let sliderItem = document.querySelectorAll(".slider-block-habillage .slider-item");
          sliderItem[4].classList.remove("end-section");
          if (sliderItem[0].classList.contains("is-active")) {
            this.isWheel();
            setTimeout(function () {
              sliderItem[0].classList.add("top-section");
            }, 800);
            if (sliderItem[0].classList.contains("top-section")) {
              this.isWheel();
              this.$refs.fullpage.api.moveTo("precis-geste");
            }
          }
        } else if (e.deltaY > 0) {
          let sliderItem = document.querySelectorAll(".slider-block-habillage .slider-item");
          sliderItem[0].classList.remove("top-section");
          if (sliderItem[4].classList.contains("is-active")) {
            this.isWheel();
            setTimeout(function () {
              sliderItem[4].classList.add("end-section");
            }, 800);
            this.isWheel();
            if (sliderItem[4].classList.contains("end-section") && e.deltaY > 0) {
              this.$refs.fullpage.api.moveTo("section-facade");
            }
          }
        }
      }
    },

    isWheel() {
      var time = 0;
      var nav = window.navigator.platform;
      //Check plateform si MacOS
      if (nav == "Mac68K" || nav == "MacPPC" || nav == "MacIntel") {
        time = 1500;
      } else {
        time = 1000;
      }

      this.isMove = true;
      setTimeout(() => {
        this.isMove = false;
      }, time);
    },

    /******************************************** */
    /*******     GESTION CAROUSSEL       *** */
    /******************************************** */
    wheelIntersectionCarousel(e, slider_block_carousel, prevSect, nextSect) {
      e.stopPropagation();
      var sliderItem = document.querySelectorAll(slider_block_carousel + " .slider-item");
      var end_section = sliderItem.length - 1;

      if (this.isMove) {
        return false;
      } else {
        if (e.deltaY < 0) {
          sliderItem[end_section].classList.remove("end-section");
          if (sliderItem[0].classList.contains("active")) {
            setTimeout(function () {
              sliderItem[0].classList.add("top-section");
            }, 400);
            if (sliderItem[0].classList.contains("top-section")) {
              this.isWheel();
              this.$refs.fullpage.api.moveTo(prevSect);
            }
          } else {
            this.isWheel();
            document.querySelector(slider_block_carousel + " .fp-prev").click();
          }
        } else if (e.deltaY > 0) {
          sliderItem[0].classList.remove("top-section");
          if (sliderItem[end_section].classList.contains("active")) {
            setTimeout(function () {
              sliderItem[end_section].classList.add("end-section");
            }, 400);
            if (sliderItem[end_section].classList.contains("end-section")) {
              this.isWheel();
              this.$refs.fullpage.api.moveTo(nextSect);
            }
          } else {
            this.isWheel();
            document.querySelector(slider_block_carousel + " .fp-next").click();
          }
        }
      }
    },

    /******************************************** */
    /*******     WHEEL INTERSECTION        *** */
    /******************************************** */
    wheelIntersection(e, sctImgFull, prevSect, nextSect) {
      e.stopPropagation();
      var sct_img_full = document.querySelector(sctImgFull + " .block-image-full");
      var sct_img_transparent = document.querySelector(sctImgFull + " .block-image-transparent");
      if (this.isMove) {
        return false;
      } else {
        if (e.deltaY < 0) {
          this.isWheel();
          sct_img_full.classList.remove("animate");
          sct_img_transparent.classList.remove("animate");
          setTimeout(function () {
            sct_img_transparent.classList.add("end-section");
            sct_img_full.classList.remove("end-section");
          }, 600);

          if (sct_img_transparent.classList.contains("end-section")) {
            this.$refs.fullpage.api.moveTo(prevSect);
          }
        } else if (e.deltaY > 0) {
          this.isWheel();
          sct_img_transparent.classList.add("animate");
          sct_img_full.classList.add("animate");
          setTimeout(function () {
            sct_img_full.classList.add("end-section");
            sct_img_transparent.classList.remove("end-section");
          }, 600);

          if (sct_img_full.classList.contains("end-section")) {
            this.$refs.fullpage.api.moveTo(nextSect);
          }
        }
      }
    },

    wheelIntersectionDestination(e) {
      if (this.isMove) {
        return false;
      } else {
        this.isWheel();
        if (e.deltaY < 0) {
          this.$refs.fullpage.api.moveTo("section-brulerie");
        } else {
          this.$refs.fullpage.api.moveTo("section-cantine");
        }
      }
    },

    /******************************************** */
    /*******  SERVICE DYNAMIQUE BEFORE AFTER  *** */
    /******************************************** */
    wheelIntersectionDynamiqueServices(e) {
      e.stopPropagation();
      let keyNumberBlock = document.querySelector(".section-dynamique-img-full");
      let beforeAfterBloc = document.querySelector(".section-dynamique-img-full .dynamique-images-flou");
      let blocKeyNumber = document.querySelector(".section-dynamique-img-full .block-key-number");
      var scrollTo;

      keyNumberBlock.scrollBy({
        top: e.deltaY,
        behavior: "smooth",
      });

      e.deltaY <= 0 ? (scrollTo = "scrollTop") : (scrollTo = "scrollBottom");

      if (this.isMove) {
        return false;
      } else {
        //Get scroll event up
        /*-------------------- */

        if (e.deltaY <= 0) {
          setTimeout(() => {
            blocKeyNumber.classList.remove("displayKeynumber");
            blocKeyNumber.classList.remove("isTop");
          }, 500);

          //Scroll to prev section si la barre de separation se situe au Top
          if (this.init >= 100) {
            this.isWheel();
            setTimeout(() => {
              this.beforeAfterBloc();
            }, 800);
            if (this.init >= 100 && this.interupteur) {
              this.$refs.fullpage.api.moveTo("section-facade");
              setTimeout(function () {
                this.init = 100;
                beforeAfterBloc.style.height = this.init + "vh";
              }, 800);
            }
          }
          //Sinon la barre continue de monter
          else if (this.init <= 100 && !blocKeyNumber.classList.contains("isTop")) {
            this.init = this.init + 100;
            beforeAfterBloc.style.height = this.init + "vh";
          }
          if (blocKeyNumber.classList.contains("displayKeynumber")) {
            this.isWheel();
          }
        }

        //Get scroll event down
        /*-------------------- */
        else {
          if (blocKeyNumber.classList.contains("displayKeynumber")) {
            this.isWheel();
            setTimeout(() => {
              this.$refs.fullpage.api.moveTo("section-carousel-1");
            }, 500);
          } else {
            //Fix key Number padding-top to 0px
            this.stopBlockPropagation(e);
            this.init = 0;
            beforeAfterBloc.style.height = this.init + "vh";

            if (this.init == 0) {
              setTimeout(() => {
                blocKeyNumber.classList.add("isTop");
              }, 500);

              this.isWheel();

              if (blocKeyNumber.classList.contains("isTop") && scrollTo == "scrollBottom") {
                blocKeyNumber.classList.add("displayKeynumber");
                setTimeout(() => {
                  this.playAnimation();
                }, 500);
                return 0;
              }
            }
          }
        }
      }
    },
    /******************************************** */
    /*******  VIVRE BEFORE AFTER  *** */
    /******************************************** */
    wheelIntersectionVivreHarmony(e) {
      e.stopPropagation();
      let keyNumberBlock = document.querySelector(".section-vivre-harmony-img-full");
      let beforeAfterBloc = document.querySelector(".section-vivre-harmony-img-full .dynamique-images-flou");
      let blocKeyNumber = document.querySelector(".section-vivre-harmony-img-full .block-key-number");
      var scrollTo;

      keyNumberBlock.scrollBy({
        top: e.deltaY,
        behavior: "smooth",
      });

      e.deltaY <= 0 ? (scrollTo = "scrollTop") : (scrollTo = "scrollBottom");

      if (this.isMove) {
        return false;
      } else {
        //Get scroll event up
        /*-------------------- */

        if (e.deltaY <= 0) {
          setTimeout(() => {
            blocKeyNumber.classList.remove("displayKeynumber");
            blocKeyNumber.classList.remove("isTop");
          }, 500);

          //Scroll to prev section si la barre de separation se situe au Top
          if (this.init2 >= 100) {
            this.isWheel();
            setTimeout(() => {
              this.beforeAfterBloc();
            }, 800);
            if (this.init2 >= 100 && this.interupteur) {
              this.$refs.fullpage.api.moveTo("section-rdc");
              setTimeout(function () {
                this.init2 = 100;
                beforeAfterBloc.style.height = this.init2 + "vh";
              }, 800);
            }
          }
          //Sinon la barre continue de monter
          else if (this.init2 <= 100 &&!blocKeyNumber.classList.contains("isTop")) {
            this.init2 = this.init2 + 100;
            beforeAfterBloc.style.height = this.init2 + "vh";
          }
          if (blocKeyNumber.classList.contains("displayKeynumber")) {
            this.isWheel();
          }
        }

        //Get scroll event down
        /*-------------------- */
        else {
          if (blocKeyNumber.classList.contains("displayKeynumber")) {
            this.isWheel();
            setTimeout(() => {
              this.$refs.fullpage.api.moveTo("section-carousel-2");
            }, 500);
          } else {
            //Fix key Number padding-top to 0px
            this.stopBlockPropagation(e);
            this.init2 = 0;
            beforeAfterBloc.style.height = this.init2 + "vh";

            if (this.init2 == 0) {
              setTimeout(() => {
                blocKeyNumber.classList.add("isTop");
              }, 500);

              this.isWheel();

              if (
                blocKeyNumber.classList.contains("isTop") &&
                scrollTo == "scrollBottom"
              ) {
                blocKeyNumber.classList.add("displayKeynumber");
                setTimeout(() => {
                  this.playAnimationVivre();
                }, 500);
                return 0;
              }
            }
          }
        }
      }
    },

    /******************************************** */
    /*******  ROOFTOP BEFORE AFTER  *** */
    /******************************************** */
    wheelIntersectionRoofTop(e) {
      e.stopPropagation();
      let keyNumberBlock = document.querySelector(".section-rooftop-img-full");
      let beforeAfterBloc = document.querySelector(".section-rooftop-img-full .dynamique-images-flou");
      let blocKeyNumber = document.querySelector(".section-rooftop-img-full .block-key-number");
      var scrollTo;

      keyNumberBlock.scrollBy({
        top: e.deltaY,
        behavior: "smooth",
      });

      e.deltaY <= 0 ? (scrollTo = "scrollTop") : (scrollTo = "scrollBottom");

      if (this.isMove) {
        return false;
      } else {
        //Get scroll event up
        /*-------------------- */

        if (e.deltaY <= 0) {
          setTimeout(() => {
            blocKeyNumber.classList.remove("displayKeynumber");
            blocKeyNumber.classList.remove("isTop");
          }, 500);

          //Scroll to prev section si la barre de separation se situe au Top
          if (this.init3 >= 100) {
            this.isWheel();
            setTimeout(() => {
              this.beforeAfterBloc();
            }, 800);
            if (this.init3 >= 100 && this.interupteur) {
              this.$refs.fullpage.api.moveTo("section-amenagement");
              setTimeout(function () {
                this.init3 = 100;
                beforeAfterBloc.style.height = this.init3 + "vh";
              }, 800);
            }
          }
          //Sinon la barre continue de monter
          else if (
            this.init3 <= 100 &&
            !blocKeyNumber.classList.contains("isTop")
          ) {
            this.isWheel();
            this.init3 = 100;
            beforeAfterBloc.style.height = this.init3 + "vh";
          }
          if (blocKeyNumber.classList.contains("displayKeynumber")) {
            this.isWheel();
          }
        }

        //Get scroll event down
        /*-------------------- */
        else {
          if (blocKeyNumber.classList.contains("displayKeynumber")) {
            this.isWheel();
            setTimeout(() => {
              this.$refs.fullpage.api.moveTo("section-carousel-3");
            }, 500);
          } else {
            //Fix key Number padding-top to 0px
            this.stopBlockPropagation(e);
            this.init3 = 0;
            beforeAfterBloc.style.height = this.init3 + "vh";

            if (this.init3 == 0) {
              setTimeout(() => {
                blocKeyNumber.classList.add("isTop");
              }, 500);
              this.isWheel();

              if (
                blocKeyNumber.classList.contains("isTop") &&
                scrollTo == "scrollBottom"
              ) {
                blocKeyNumber.classList.add("displayKeynumber");
                return 0;
              }
            }
          }
        }
      }
    },
  },

  mounted() {
    this.initOnglet();
    //Play Video background
    setTimeout(() => {
      var video = document.querySelector(".section-first .bg-hero");

      //Show title video background
      setTimeout(() => {
        this.AnimtxtBlur = true;
      }, 3000);

      if (video) {
        video.play();

        var inter = setInterval(() => {
          if (video.paused && video.ended == false) {
            video.play();
          }
        }, 500);

        video.addEventListener("ended", function () {
          clearInterval(inter);
        });
      }
    }, 1500);

    /*Diffère tous les images*/
      let loadImgImg = document.querySelectorAll(".loadTemp img");

      for (let i = 0; i < loadImgImg.length; i++) {
        loadImgImg[i].setAttribute("srcset",loadImgImg[i].getAttribute("dta-srcset"));
        // loadImgSource[i].setAttribute("srcset",loadImgSource[i].getAttribute("dta-srcset"));
        loadImgImg[i].setAttribute("src",loadImgImg[i].getAttribute("dta-src"));
      } 
  },
  watch: {
    carouselData() {
      this.$refs.carouselHarmony.slideTo(this.carouselData);
    },
  },
};
</script>
